import classes from './styles.module.scss';
import clsx from 'clsx';

export function PriceCard(props) {
  const { title, price, accent, shadowRight, shadowLeft } = props;

  return (
    <div
      className={clsx(classes.cardContainer, {
        [classes.accent]: accent,
        [classes.shadowLeft]: shadowLeft,
        [classes.shadowRight]: shadowRight,
      })}
    >
      <p className={classes.title}>{title}</p>
      <p className={classes.price}>{price}</p>
    </div>
  );
}
