import React from "react";
import { EffectCards } from "swiper/modules";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import classes from "./styles.module.scss";

const images = [
  { name: "Clay", src: "clay.png" },
  { name: "3D", src: "3d.png" },
  { name: "Video game", src: "video-game.png" },
  { name: "Emoji", src: "emoji.png" },
  { name: "Pixel", src: "pixel.png" },
];

export function SwiperBlock(props) {
  const { classNames } = props;

  return (
    <div className={clsx(classes.swiperWrapper, classNames)}>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className={classes.swiper}
      >
        {images.map((image) => (
          <SwiperSlide className={classes.slide} key={image.name}>
            <img
              src={`${process.env.REACT_APP_ASSET_PREFIX}${image.src}`}
              alt={image.name}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={classes.style}></div>
    </div>
  );
}
