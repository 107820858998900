import { TG_CONTACTS_LINK, TG_BOT_LINK } from "../../constants";
import { Button } from "../button";
import classes from "./styles.module.scss";

export function Header() {
  return (
    <header className={classes.headerContainer}>
      <img
        className={classes.logo}
        src={`${process.env.REACT_APP_ASSET_PREFIX}logo.svg`}
        alt="logo"
      />
      <div className={classes.navContainer}>
        <nav>
          <ul>
            <li>
              <a href="#price">Прайс</a>
            </li>
            <li>
              <a href={TG_CONTACTS_LINK}>Контакты</a>
            </li>
          </ul>
        </nav>
        <Button label="Попробовать" href={TG_BOT_LINK} />
      </div>
    </header>
  );
}
