import { TG_BOT_LINK } from '../../constants';
import { PriceCard } from '../price-card';
import { Button } from '../button';
import { Label } from '../label';
import classes from './styles.module.scss';
import { SwiperBlock } from '../swiper';

export function Main() {
  return (
    <main className={classes.mainContainer}>
      <section className={classes.mainSection}>
        <img src={`${process.env.REACT_APP_ASSET_PREFIX}main-image.png`} alt="main image" />
        <h1>FaceToMany телеграм-БОТ</h1>
        <p>
          Преврати лицо в 3D стиль, эмодзи, пиксельную иллюстрацию, персонажа
          видеоигры или пластилиновую фигуру
        </p>
        <Button label="Попробовать" href={TG_BOT_LINK} />
        <span>40к+ пользователей</span>
      </section>
      <section className={classes.stylesSection}>
        <Label text="Стили" />
        <SwiperBlock classNames={classes.carousel} />
      </section>
      <section className={classes.priceSection}>
        <Label text="Прайс" id="price" />
        <h2>Выберите количество генераций</h2>
        <p className={classes.subtitle}>
          Генерации будут зачислены сразу после оплаты
        </p>
        <div className={classes.priceContainer}>
          <PriceCard title="5 генераций" price="69₽" shadowLeft />
          <PriceCard title="50 генераций" price="589₽" accent />
          <PriceCard title="20 генераций" price="249₽" shadowRight />
        </div>
        <Button label="Оплатить" href={TG_BOT_LINK} />
      </section>
    </main>
  );
}
