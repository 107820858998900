import classes from './styles.module.scss';

export function Label(props) {
  const { text, id } = props;

  return (
    <div id={id} className={classes.label}>
      {text}
    </div>
  );
}
