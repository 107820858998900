import classes from './styles.module.scss';
export function Button(props) {
  const { label, href } = props;

  if (href) {
    return (
      <a href={href} className={classes.button}>
        {label}
      </a>
    );
  }

  return <button className={classes.button}>{label}</button>;
}
