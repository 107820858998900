import { TG_BOT_LINK } from "../../constants";
import classes from "./styles.module.scss";

export function Footer() {
  return (
    <footer className={classes.footerContainer}>
      <img className={classes.logo} src="/logo.svg" alt="logo" />
      <div className={classes.footerNav}>
        <a href={TG_BOT_LINK} className={classes.link}>
          Оферта
        </a>
        <a href={TG_BOT_LINK} className={classes.link}>
          <img
            className={classes.logo}
            src={`${process.env.REACT_APP_ASSET_PREFIX}tg-logo.png`}
            alt="logo"
          />
        </a>
      </div>
    </footer>
  );
}
